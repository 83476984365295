.parent {
  width: 100%;
  height: 100vh;    
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.img {
  width: 250px;
}

.progress {
  width: 82px;
  height: 10px;
  -webkit-mask: linear-gradient(90deg,#059042 70%,#0000 0) left/20% 100%;
  background: linear-gradient(#059042 0 0) left/0% 100% no-repeat
      #e6f0dd;
  animation: progress-422c3u 1.6s infinite steps(6);
}

@keyframes progress-422c3u {
  100% {
     background-size: 120% 100%;
  }
}